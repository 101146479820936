export default {
  loading: 'Loading...',
  'no-update': 'Already the latest version',
  'load-more': 'Load More',
  'copy-success': 'Copy Successfully',
  'invalid-url': 'Invalid Url',
  home: {
    tab: {
      home: 'Home',
      result: 'Result',
      casino: 'Casino',
      scratch: 'Scratch',
      scratchOff: 'Scratch Off',
      referral: 'Referral',
      me: 'Me',
      live: 'Live',
      liveCasino: 'Live Casino',
      sports: 'Sports',
      lottery: 'Lottery',
      promotions: 'Promotions',
      invite: 'Invite',
      wallet: 'Wallet',
      proxyhome: 'Agent',
    },
    scratch: {
      title: 'Scratch',
      free: 'FREE',
    },
    kerala: {
      title: 'Kerala State Lottery',
      no: 'NO.',
      lastResult: 'Last Jackpot Outcome',
    },
    digit: {
      title: '3 Digit Game',
      booking: 'Time for Next Booking',
      ticket: 'Ticket',
      price: 'Ticket Price',
      lastResult: 'Last Draw Outcome',
      drawTime: 'Next Draw Time',
    },
    dice: {
      title: 'Dice Game',
      play: 'Play Now',
    },
    color: {
      title: 'Color Prediction',
    },
    matka: {
      bid: 'BID',
      win: 'WIN',
      OPEN: 'OPEN',
      CLOSE: 'CLOSE',
      open: 'Open',
      close: 'Close',
      title: 'Satta Matka',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    car: {
      title: 'Quick Race',
      min: 'min',
      text: 'Next draw starts in',
    },
    tip: {
      closed: '{{name}} is closed now, please choose next game',
      copied: 'Copy successfully, please share with friends',
    },
    label: {
      menu: 'Menu',
      viewAll: 'View All',
      buyNow: 'Buy Now',
      'lucky-spin': 'Lucky Spin',
      rebate: 'Rebate',
      'free-lottery': 'Free Lottery',
      referral: 'Referral',
      'refer-friend': 'Refer Friend',
      agent: 'Agent',
      biggestWins: 'Biggest Wins',
      jackpotGames: 'Jackpot Games',
      onlineUser: '{{number}} users playing',
      popularGame: 'The most popular game',
      provedores: 'Provedores',
      allGames: 'All Games',
      more: 'More',
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      total: 'Total',
      bet: 'Bet',
      online: 'Online',
      winningAmount: 'Winning Amount',
      imgFail: 'Image failed to load',
    },
    luckyspin: {
      add: 'Add',
      spin: 'SPIN',
      won: 'Won',
      winner: 'Big Winners',
      mySpin: 'My Spin',
    },
    title: {
      scratchOff: 'Scratch Off',
      casino: 'Casino',
      live: 'Live',
      sports: 'Sports',
      lottery: 'Lottery',
      scratch: 'Scratch',
      servertime: 'Server Uptime Days',
      winningInformation: 'Winning Information',
      todayEarningsChart: "Today's earnings chart",
    },
  },
  casino: {
    sortby: 'Sort by',
    categories: 'Categories',
    popular: 'Popular',
    all: 'All',
    lobby: 'Lobby',
  },
  me: {
    webview: {
      vip: 'Member Center',
    },
    new: 'NEW',
    user: {
      userId: 'User ID',
      moreExciting: 'More exciting after logging in',
      login: 'Log in',
      loginUpper: 'LOGIN',
      uid: 'UID:',
      lastLogin: 'Last login:',
    },
    money: {
      totalWallet: 'Total Wallet',
      myWallet: 'My Wallet',
      cashBalance: 'Cash Balance',
      withdrawAmount: 'Withdrawable Amount',
      totalBalance: 'Total balance',
      wallet: 'Wallet',
      recharge: 'Recharge',
      withdraw: 'Withdraw',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result history',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer service',
      update: 'Update',
      password: 'Password',
    },
    language: {
      switch: 'Switch Language',
      english: 'English',
    },
    tip: {
      cashTitle: 'Cash Balance:',
      cashContent:
        '"Cash balance" refers to the actual cash funds available in your account that can be used for betting or other transactions.',
      withdrawTitle: 'Withdrawable Amount:',
      withdrawContent:
        '"Withdrawable amount" is the portion of your cash balance that can be withdrawn from your account. It represents the money that has met the wagering requirements, which need to be fulfilled before the funds can be withdrawn.',
    },
  },
  invitation: {
    title: {
      singUp: 'Free lottery tickets',
    },
    label: {
      claim: 'Claim your Ticket',
    },
  },
  push: {
    mainText: 'This website would like to send you awesome updates and offers!',
    secText: 'Notifications can be turned off anytime from browser settings.',
    allow: 'Allow',
    block: "Don't Allow",
  },
};
